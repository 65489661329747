@tailwind base;
@tailwind components;
@tailwind utilities;
/* @import url('http://fonts.cdnfonts.com/css/gilroy-bold'); */
@font-face {
  font-family: "Gilroy-bold";
  src: local("Gilroy-bold"),
    url(./assets/font/gilroy/gilroy/Gilroy-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Gilroy-medium";
  src: local("Gilroy-medium"),
    url(./assets/font/gilroy/gilroy/Gilroy-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "Gilroy-semibold";
  src: local("Gilroy-semibold"),
    url(./assets/font/gilroy/gilroy/Gilroy-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "Gilroy-extrabold";
  src: local("Gilroy-extrabold"),
    url(./assets/font/gilroy/gilroy/Gilroy-ExtraBold.ttf) format("truetype");
}

body {
  margin: 0;
  font-family: Gilroy-medium;
  background-color: #FFD456;
  background-image: url("./assets/grid2.svg");
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@layer utilities {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: Gilroy-medium;
  }

  p {
    font-family: Gilroy-medium;
  }
}

.shadowText {
  text-shadow: -1px 1px 0px rgba(0, 0, 0, 0.25);
}
.tooltip {
  position: relative;
  display: flex;
}

.tooltip .tooltiptext {
  visibility: hidden;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

/* popup background */
.tooltip2 {
  position: absolute;
  display: flex;
}

.tooltip2 .tooltiptext2 {
  visibility: hidden;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltip2:hover .tooltiptext2 {
  visibility: visible;
}

/* CHANGE BG */
.yellow-bg {
  background-color: #FFD456;
  background-image: url("./assets/grid2.svg");
}

.green-bg {
  background-color: #356733;
  background-image: url("./assets/gridGreen.svg");
}

#root{
  height: 100vh;
  width: 100%;
}

/*CUSTOM SCROLLBAR*/
::-webkit-scrollbar {
  @apply h-2 w-3
}
::-webkit-scrollbar-track {
  background-color: rgba(79, 79, 79, 0.25);
}
::-webkit-scrollbar-thumb {
  border-radius: 100vh;
  background-color: rgba(0, 0, 0, 0.25);
  -webkit-border-radius: 100vh;
  -moz-border-radius: 100vh;
  -ms-border-radius: 100vh;
  -o-border-radius: 100vh;
}

.cursor-pen{
  cursor: url('./assets/pen-pointer.svg') 4 24, pointer; 
}

.cursor-erase{
  cursor: url('./assets/erase-pointer.svg') 4 24, pointer; 
}

.nav-tabs .nav-link.active {
  @apply text-green-1 border-green-1 font-gilroyBold text-lg;
}

.nav-tabs .nav-link{
  @apply text-lightgrey-700
}

/* UPLOAD IMAGE */
.upload-image {
  font-size: 12px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 1200px) {
  #root {
    width: 100%;
  }
}

@media screen and (max-width: 555px) {
  #root{
    width: fit-content;
  }
}

.navLinkDashboard > .active {
  background-color: #356733;
  color: white;
}